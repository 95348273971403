const userRatingLike = (id, tourId, liked, likesCount) => ({
    id: id,
    liked: !!liked,
    likesCount: likesCount,
    inProgress: false,
    apiUrl: '/presentation/api/tours/' + tourId + '/ratings/' + id + '/like',
    async toggleLike() {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;

        if (this.liked) {
            this.likesCount++;

            const response = await fetch(this.apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    csrfToken: document.querySelector('meta[name="csrfToken"]').content
                }),
            });

            if (response.status !== 201) {
                this.likesCount--;
            }
        } else {
            this.likesCount--;
            const response = await fetch(this.apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    csrfToken: document.querySelector('meta[name="csrfToken"]').content
                }),
            });

            if (response.status !== 204) {
                this.likesCount++;
            }
        }

        this.inProgress = false;
    }
})

export default userRatingLike;