const checkbox = (name, defaultValue) => ({

    checkbox: defaultValue,
    animations: false,

    init() {
        this.$watch('checkbox', value => this.$dispatch('form-update-value', { name, value }))

        setTimeout(() => {
            this.animations = true
        }, 300)
    },
})

export default checkbox
