import Alpine from 'alpinejs'
import morph from '@alpinejs/morph'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import cookieBanner from '../presentation/components/cookieBanner'
import contentMenu from '../presentation/components/contentMenu'
import tooltip from '../presentation/binds/tooltip'
import formSearchAccommodation from '../presentation/components/form/search/accommodation'
import dateRange from '../presentation/components/form/datepicker/dateRange'
import formInput from '../presentation/components/form/input'
import formAutocomplete from '../presentation/components/form/autocompleter'
import formAutocompleteWithResource from '../presentation/components/form/autocompleterWithResource'
import button from '../presentation/components/common/button'
import header from '../presentation/components/header/header'
import headerAutocompleter from '../presentation/components/header/autocompleter'
import skiresorts from '../presentation/components/cards/listing/skiresorts'
import pagination from '../presentation/components/common/pagination'
import track from '../presentation/components/track' //native javascript tracking
import tracking from '../presentation/components/common/track' //apline js tracking
import modal from '../presentation/components/common/modal'
import modalAjax from '../presentation/components/common/modalAjax'
import formSearchSkiresort from '../presentation/components/form/search/skiresort'
import formSearchSummerregion from '../presentation/components/form/search/summerregion'
import rangeSlider from '../presentation/components/form/filter/rangeSlider'
import filterSkiresorts from '../presentation/components/form/filter/skiresorts'
import checkbox from '../presentation/components/form/filter/checkbox'
import stickyBanner from '../presentation/components/banner/sticky'
import contentAds from '../presentation/components/promotion/contentAds'
import favorites from '../presentation/components/common/favorites'
import user from '../presentation/stores/user'
import map from './stores/map'
import storeHeader from '../presentation/stores/header'
import modalStore from '../presentation/stores/modal'
import slider from '../presentation/components/splide/slider'
import formValidator from '../presentation/components/form/formValidator'
import newsletter from '../presentation/components/common/newsletter'
import rating from '../presentation/components/tours/rating'
import filterTours from '../presentation/components/form/filter/tours'
import categorySelect from '../presentation/components/form/filter/categorySelect'
import userRatingLike from '../presentation/components/tours/userRatingLike'
import report from '../presentation/components/common/report'
import tours from '../presentation/components/tours/tours'
import notification from '../presentation/stores/notification.js'
import notify from '../presentation/magics/notify.js'

window.Alpine = Alpine

contentMenu()

// tagmanager tracking
track()

cookieBanner()
contentAds()

// plugins
Alpine.plugin(morph)
Alpine.plugin(focus)
Alpine.plugin(intersect)

Alpine.bind('tooltip', (text) => tooltip(text))

// alpine data
Alpine.data('datepickerRange', (min, max) => dateRange(min, max))
Alpine.data('formInput', (data) => formInput(data))
Alpine.data('formAutocomplete', (data) => formAutocomplete(data))
Alpine.data('formAutocompleteWithResource', (data) => formAutocompleteWithResource(data))
Alpine.data('formSearchAccommodation', (action) => formSearchAccommodation(action))
Alpine.data('formSearchSkiresort', () => formSearchSkiresort())
Alpine.data('formSearchSummerregion', () => formSearchSummerregion())
Alpine.data('button', button)
Alpine.data('header', header)
Alpine.data('headerAutocomplete', (data) => headerAutocompleter(data))
Alpine.data('modal', modal)
Alpine.data('modalAjax', modalAjax)
Alpine.data('listingSkiresorts', (baseUrl, filter) => skiresorts(baseUrl, filter))
Alpine.data('pagination', pagination)
Alpine.data('formFilterRangeSlider', (min, max, valueMin, valueMax, step, name) => rangeSlider(min, max, valueMin, valueMax, step, name))
Alpine.data('formFilterCheckbox', (name, defaultValue) => checkbox(name, defaultValue))
Alpine.data('formFilterSkiresorts', filterSkiresorts)
Alpine.data('stickyBanner', stickyBanner)
Alpine.data('tracking', tracking)
Alpine.data('slider', slider)
Alpine.data('favorites', favorites)
Alpine.data('formValidator', formValidator)
Alpine.data('newsletter', newsletter)
Alpine.data('tourRating', rating)
Alpine.data('formFilterTours', filterTours)
Alpine.data('searchFilterTours', (baseUrl, filter) => tours(baseUrl, filter))
Alpine.data('formFilterCategorySelect', categorySelect)
Alpine.data('formFilterTypeSelect', (parentUrl) => categorySelect(parentUrl))
Alpine.data('userRatingLike', userRatingLike)
Alpine.data('report', report)

// alpine store
Alpine.store('user', user)
Alpine.store('map', map)
Alpine.store('header', storeHeader)
Alpine.store('modal', modalStore)
Alpine.store('notification', notification)

// alpine magics
Alpine.magic('notify', notify)

Alpine.start()
